
/* eslint max-lines: off */
import {computed, defineComponent, nextTick, onMounted, PropType, reactive, ref, watch} from "vue";
import {ClientManager} from "@/singletons/ClientManager";
import {
	CustomValidationActionDto,
	DocumentField,
	DocumentTable,
	DocumentTableCell,
	DocumentTableColumn,
	DocumentTableRow,
	FieldLookupFilter,
	TableColumnWidth
} from "@dex/squeeze-client-ts";
//import DataTable from "primevue/datatable";
import DataTable from "@/components/datatable/DexDataTable.vue";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import AutoComplete from "@/components/DexAutocomplete.vue";
import {AutoCompleteOnCompleteEvent} from "@/shims-prime-vue";
import {LookupDefinition, ValidationFieldDto} from "@dex/squeeze-client-ts";
import {useSqueezeStore} from "@/apps/squeeze/store";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {ToastManager} from "@/util/ToastManager";

interface DocumentTableCellWithIndex extends DocumentTableCell {
	cellIndex: number;
}

interface ColumnWidth extends DocumentTableColumn {
	colWidth?: string;
}

interface UiTableRow {
	model: DocumentTableRow;
	cells: {
		[columnName: string]: DocumentTableCell;
	};
	pos: number;
}

interface UiTableCell {
	pos: number;
	name: string;
	element: any;
	column: DocumentTableColumn;
}

interface LookupDefinitionWithFilters extends LookupDefinition {
	lookupFieldFilters: FieldLookupFilter[];
}

interface DocumentTableColumnWithLookupFilter extends DocumentTableColumn{
	lookup?: LookupDefinitionWithFilters;
}

export default defineComponent({
	name: "ValidationTable",
	components: {
		DataTable,
		Column,
		InputText,
		AutoComplete,
	},
	props: {
		/** List of all DocumentFields of document */
		documentFields: {
			type: Array as PropType<ValidationFieldDto[]>,
			default: () => [],
		},
		currentTable: {
			type: Object as PropType<DocumentTable>,
			default: () => ({}),
		},
		/** Should the buttons of the table be hidden? */
		hideButtons: {
			type: Boolean,
			default: false,
		},
		/** Is the current mode of component readonly? */
		isReadOnlyMode: {
			type: Boolean,
			default: false,
		},
		/** Current documentId **/
		documentId: {
			type: Number,
			required: true,
		},
		/** Current document class ID **/
		documentClassId: {
			type: Number,
			required: true,
		},
		/** Reset all selected rows of current table after execution of user exits */
		resetSelectedRows: {
			type: Boolean,
			default: false,
		},
		customTableActions: {
			type: Array as PropType<CustomValidationActionDto[]>,
			default: () => [],
		},
	},
	emits: [
		'onFocusField',
		'onChange',
		"onChangeTable",
		'allTableRefCells',
		'focusLastHeadField',
		'executeCustomTableAction',
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Current Vuex-Store */
		const store = useSqueezeStore();

		/** Api for document */
		const documentService = ClientManager.getInstance().squeeze.document;

		/** Api for document class */
		const documentClassService = ClientManager.getInstance().squeeze.documentClass;

		/** Show Loading on load data */
		const loading = ref<boolean>(false);

		const table = reactive<DocumentTable>({
			id: 0,
			documentClassId: 0,
			fieldGroupId: 0,
			locatorId: 0,
			name: "",
			description: "",
			mandatory: false,
			readonly: false,
			hidden: false,
			forceValidation: false,
			externalName: "",
		});

		/** Array with all row-values */
		const rows = ref<DocumentTableRow[]>([]);

		/** Array with Table-Fields */
		const columns = ref<DocumentTableColumn[]|null>(null);

		/** Should the row be edited? */
		const editingRows = ref<UiTableRow[]>([]);

		/** Array with the currently filtered values */
		const filteredValues = ref<any[]>([]);

		/** Object with the current default-values  */
		const defaultValues = reactive<{[key: string]: any}>({});

		/** Array with Table-Cells  */
		const tableCells =ref<UiTableCell[]>([]);

		/** Next Table-Cell by Enter or Tab */
		const nextTableCell = ref<HTMLElement|null>(null);

		/** List of readonly-columns */
		const noFocusColumns = ref<DocumentTableColumn[]>([]);

		/** Should the buttons of the table be hidden? */
		const showButton = ref<boolean>(false);

		/** Currently selected rows */
		const selectedRows = ref<UiTableRow[]>([]);

		/** List of all table column widths */
		const allTableColumnWidths = ref<TableColumnWidth[]>([]);

		/** All visible columns of a table */
		const allVisibleColumns = computed(() => {
			// Filter all visible cells from Array
			if (columns.value && columns.value.length > 0) {
				return columns.value.filter((column: DocumentTableColumn) => column.hidden === false);
			}
			return [];
		});

		/** Set table column width */
		const setTableColumnWidth = () => {
			const allVisibleTableColumns: ColumnWidth[] = [];
			if (!columns.value) {
				return;
			}

			columns.value.forEach((column: ColumnWidth) => {
				allTableColumnWidths.value.find((tableColumnWidth: TableColumnWidth) => {
					if (tableColumnWidth.tableFieldId === column.id && tableColumnWidth.width !== '0') {
						column.colWidth = tableColumnWidth.width;
					}
					if (!column.hidden) {
						allVisibleTableColumns.push(column);
					}
				})
			});

			// remove on the last visible column the width, when all columns have a column width
			const allColumnsHasPropertyColWidth: boolean = allVisibleTableColumns.every((tableColumn: ColumnWidth) => tableColumn.colWidth);
			if (columns.value.length !== allVisibleTableColumns.length && allColumnsHasPropertyColWidth) {
				allVisibleTableColumns.forEach((column: ColumnWidth, columnIndex: number) => {
					if (columnIndex === allVisibleTableColumns.length -1) {
						column.colWidth = '';
					}
				})
			}
		}

		/** Get all table column widths */
		const getAllTableColumnWidths = () => {
			if (table && table.id) {
				loading.value = true;
				documentClassService.getAllDocumentClassTableColumnWidths(props.documentClassId, table.id)
					.then((allColumnWidths: TableColumnWidth[]) => {
						allTableColumnWidths.value = allColumnWidths;

						if (allColumnWidths.length > 0) {
							setTableColumnWidth();
						}
					})
					.catch((response: any) => response.json().then ((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}))
					.finally(() => {
						loading.value = false;
					})
			}
		}

		/** Trigger the column resize
		 * @param event
		 */
		const columnResize = (event: any) => {
			// convert px to rem
			const columnWidthInRem = (1 / 16 * parseFloat(event.element.offsetWidth));

			// get current column
			const currentColumn = columns.value!.find((column: ColumnWidth) => column.description === event.element.innerText) as ColumnWidth;

			if (currentColumn && currentColumn.id && columnWidthInRem && table && table.id) {
				if (currentColumn.colWidth || typeof currentColumn.colWidth !== 'undefined') {
					documentClassService.putDocumentClassTableColumnWidth(props.documentClassId, table.id, currentColumn.id, String(columnWidthInRem))
						.catch((response: any) => response.json().then ((err: { message: string }) => {
							ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						}));
				} else {
					documentClassService.createDocumentClassTableColumnWidth(props.documentClassId, table.id, currentColumn.id, String(columnWidthInRem))
						.then(() => {
							currentColumn.colWidth = String(columnWidthInRem);
						})
						.catch((response: any) => response.json().then ((err: { message: string }) => {
							ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						}));
				}
			}
		}

		/**
		 * Reloads the Table-data
		 * @param {boolean} reloadColumns
		 */
		const reloadData = (reloadColumns: boolean = true) => {
			editingRows.value = [];

			if (!table || !table.columns) {
				return;
			}

			// If the columns are always reloading, there will be errors with focusing
			if (reloadColumns) {
				columns.value = table.columns;
				noFocusColumns.value = table.columns.filter(col => col.readonly || col.hidden);
			}

			const rows = table.rows;

			if (rows) {
				let cellIndex = 0;
				editingRows.value = rows.map((row: DocumentTableRow, index: number) => {
					const editingRow: UiTableRow = {
						model: Object.assign({}, row),
						pos: (index + 1),
						cells: {},
					};

					row.cells?.forEach((cell: any) => {
						const cellWithIndex = cell as DocumentTableCellWithIndex;
						if (!noFocusColumns.value.find(col => col.name === cell.columnName)) {
							cellWithIndex.cellIndex = cellIndex;
							cellIndex++;
						}
						editingRow.cells[cell.columnName!] = cellWithIndex;
					});

					return editingRow;
				});
			} else {
				// Create Reference to Rows-Object if there is no Reference before
				table.rows = [];
			}

			/** Create default-values */
			if (columns.value) {
				columns.value.forEach(column => {
					if (defaultValues) {
						defaultValues["id"] = 0;
						if (column.name) {
							defaultValues[column.name] = {};
							defaultValues[column.name].value = "";
						}
					}
				})
			}
		}

		/**
		 * Event that is triggered when an item is selected on Autocomplete
		 * @param event Event of Autocomplete
		 * @param documentClassField Current documentClassField
		 * @param row Current row
		 * @param cellData Current cell data
		 */
		const onItemSelect = (event: any, documentClassField:  DocumentTableColumn, row: UiTableRow, cellData: DocumentTableCell) =>{
			// Set the field-value to "value", not the label
			if (documentClassField && documentClassField.name) {
				row.cells[documentClassField.name].value = event.value.value;

				if (cellData.state === "FORCEAPPROVAL") {
					cellData.state  = "OK";
					emit("onChange", table);
				}
			}
		}

		/**
		 * Event that is triggered when users make autocomplete-inputs
		 * @param event Event of Autocomplete
		 * @param field Current documentClassField
		 * @param data Current table row data
		 */
		const searchAutocomplete = async (event: AutoCompleteOnCompleteEvent, field: DocumentTableColumn, data: UiTableRow) => {
			const rows = await documentService.getDocumentTableCellLookupValues(props.documentId, table.id!, field.id!, {
				documentFields: props.documentFields,
				fieldSearchValue: event.query,
				documentTableRow: data.model,
			})
			const resultColumns = field.lookup?.resultValueColumnIds;
			const alternatives = rows
				.map(row => {
					const completeValue = row;
					const value = row.displayColumnResults![field.lookup!.resultKeyColumnId];
					const label = resultColumns?.map(col => row.displayColumnResults![col!]!).join(" | "); // Map result columns to a single string to be displayed
					return {value, label, completeValue};
				})

			filteredValues.value[field.name! as any] = alternatives;
		}

		/**
		 * Focus the active cell
		 * @param cell
		 */
		const activateCell = (cell?: UiTableCell) => {
			if (cell && cell.element && cell.column && cell.column.lookup) {
				const $el = cell.element.$el;
				const el = (!cell.column.lookup.active || props.isReadOnlyMode ? $el : $el.firstElementChild) as HTMLInputElement;
				nextTableCell.value = el;

				el.focus({ preventScroll: false });
			}
		}

		/** Scroll to table bottom */
		const scrollToTableBottom = async () => {
			await nextTick();

			const lengthOfAllTableRows = table.rows ? table.rows.length : 0;
			const firstCellOfLastRow = tableCells.value.find(cell => cell.pos == lengthOfAllTableRows);

			if (firstCellOfLastRow) {
				activateCell(firstCellOfLastRow);
			}
		}

		/** Create empty row model object */
		const createEmptyRowModelObject = () => {
			/** Create default-values */
			const row = {
				cells: [] as DocumentTableCell[],
				value: {
					value: '',
					page: 0,
					text: '',
					type: '',
					confidence: 0,
				},
			}

			if (!columns.value) {
				return row;
			}

			const cells: DocumentTableCell[] = [];
			columns.value.forEach(column => {
				const cell: DocumentTableCell = {
					columnName: column.name,
					value: '',
				}

				cells.push(cell);
			})
			row.cells = cells;

			return row;
		}

		/**
		 * Creates a new row and adds it to the bottom if the table.
		 * @param currentRowIndex
		 */
		const createNewRow = async (currentRowIndex: number) => {
			if (defaultValues && editingRows.value && table.rows) {
				const model = createEmptyRowModelObject();

				if (currentRowIndex) {
					// Allow inserting at any place in the table
					table.rows.splice(currentRowIndex, 0, model);
				} else {
					table.rows.push(model);
				}

				// Map table-rows to editing-table
				tableCells.value.splice(0);
				let cellIndex = 0;
				editingRows.value = table.rows.map((row: DocumentTableRow, index: number) => {
					const editingRow: UiTableRow = {
						model: Object.assign({}, row),
						pos: (index + 1),
						cells: {},
					};

					row.cells?.forEach((cell: DocumentTableCell) => {
						const cellWithIndex = cell as DocumentTableCellWithIndex;
						if (!noFocusColumns.value.find(col => col.name === cell.columnName)) {
							cellWithIndex.cellIndex = cellIndex;
							cellIndex++;
						}
						editingRow.cells[cell.columnName!] = cellWithIndex;
					});

					return editingRow;
				});
			}

			// validate the new rows
			emit("onChange");

			if (!currentRowIndex) {
				await scrollToTableBottom();
			} else {
				await nextTick();
				const firstCellOfCopiedRow = tableCells.value.find(cell => cell.pos == currentRowIndex +1);
				activateCell(firstCellOfCopiedRow);
			}
		}

		/**
		 * Creates a copy row of the currentRow and adds it to the next line if the table.
		 * @param currentRowIndex
		 */
		const copyRow = async (currentRowIndex: number) => {
			const currentRow: DocumentTableRow | undefined = table.rows?.find((row, index: number) => index === currentRowIndex - 1);

			if (currentRow && table.rows) {
				table.rows.splice(currentRowIndex, 0, currentRow);
				let cellIndex = 0;
				editingRows.value = table.rows.map((row: DocumentTableRow, index: number) => {
					const editingRow: UiTableRow = {
						model: Object.assign({}, row),
						pos: (index + 1),
						cells: {},
					};

					row.cells?.forEach((cell: any, indexCol) => {
						const cellWithIndex = cell as DocumentTableCellWithIndex;
						if (!noFocusColumns.value.find(col => col.name === cell.columnName)) {
							cellWithIndex.cellIndex = cellIndex;
							cellIndex++;
						}
						editingRow.cells[cell.columnName!] = cellWithIndex;
					});

					return editingRow;
				});
			}

			// validate the new rows
			emit("onChange");

			await nextTick();
			const firstCellOfCopiedRow = tableCells.value.find(cell => cell.pos == currentRowIndex +1);
			activateCell(firstCellOfCopiedRow);
		}

		/** Reset all column widths of table */
		const resetColumnWidths = () => {
			if (columns.value && table && table.id) {
				columns.value.forEach((column: ColumnWidth) => {
					if (typeof column.colWidth !== 'undefined' && column.id) {
						documentClassService.putDocumentClassTableColumnWidth(props.documentClassId, table.id!, column.id, '')
							.catch((response: any) => response.json().then ((err: { message: string }) => {
								ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
							}))
					}
					column.colWidth = '';
				})
			}
		}

		/** Deletes all selected rows in table */
		const deleteSelectedRows = () => {
			// delete all rows
			if (selectedRows.value.length === editingRows.value.length) {
				editingRows.value.splice(0);
				table.rows = [];
				selectedRows.value = [];
				emit("onChangeTable", table);
				return;
			}

			selectedRows.value.forEach(selectedRow => {
				const selectedRowIndex = editingRows.value.findIndex(editingRow => editingRow.pos === selectedRow.pos);
				if (selectedRowIndex || selectedRowIndex === 0) {
					// delete the selected row
					editingRows.value.splice(selectedRowIndex, 1);

					selectedRows.value = [];
				}
			});

			// set new row position
			editingRows.value.forEach((row, index) => {
				row.pos = (index + 1);
			});

			table.rows = editingRows.value.map(row => {
				return {
					value: row.model.value,
					cells: row.model.cells,
				}
			});

			emit("onChangeTable", table);
		}

		/**
		 * Find cell
		 * @param row
		 * @param col
		 */
		const findCell = (row: number, col?: DocumentTableColumn) => {
			if (col) {
				return tableCells.value.find(cell => cell.pos === row && cell.name === col.name);
			}
			return tableCells.value.find(cell => cell.pos === row);
		}

		/**
		 * Deletes a row the table
		 * @param {number} index
		 */
		const deleteRow = (index: number) => {
			editingRows.value.splice(index -1, 1);

			editingRows.value.forEach((row, index) => {
				row.pos = (index + 1);
			});

			table.rows = editingRows.value.map(row => {
				return {
					value: row.model.value,
					cells: row.model.cells,
				}
			});

			emit("onChangeTable", table);

			if (editingRows.value.length >= index) {
				activateCell(findCell(index));
			} else if (editingRows.value.length) {
				activateCell(findCell(editingRows.value.length));
			}
		}

		/** Creates the default values that are used for new entries */
		const createDefaultValues = () => {
			/** Create default-values */
			if (!columns.value) {
				return;
			}

			columns.value.forEach(column => {
				if (defaultValues) {
					defaultValues["id"] = 0;
					if (column.name) {
						defaultValues[column.name] = {};
						defaultValues[column.name].value = "";
					}
				}
			})
		}

		/**
		 * Triggered when a field is focused
		 * @param event
		 * @param cell
		 * @param row
		 * @param col
		 */
		const onFocusField = (event: FocusEvent, cell: DocumentTableCell, row: UiTableRow, col: DocumentTableColumn) => {
			if (event && event.target) {
				(event.target as HTMLInputElement).select();
			}

			// Remove Reference and create Document Field from cell-data (for error-messages)
			const tableField = JSON.parse(JSON.stringify(cell)) as DocumentField;
			tableField.description = col.description  + " (" + t("Squeeze.Validation.General.Row") + " " + row.pos + ")";
			tableField.value = {
				value: cell.value,
				state: cell.state,
				errorText: cell.errorText,
			}

			// Set message for fields, TODO: change when backend does this
			if (cell.errorCode !== -1 && cell.errorCode != null) {
				tableField.value!.errorText = t("Squeeze.Validation.ErrorCode." + cell.errorCode);
			} else if (cell.state?.toLowerCase() === "ok") {
				tableField.value!.errorText = t("Squeeze.Validation.ErrorCode.0");
			}

			emit("onFocusField", cell, row.model, row.pos, tableField);
		}

		/**
		 * Check if table row in viewport
		 * @param parent table wrapper
		 * @param row current row element
		 * @returns returns a boolean if row in parent bounding client rect
		 */
		const isTableRowInViewport = (parent: Element, row: Element) => {
			const rowRect = row.getBoundingClientRect();
			const parRect = parent.getBoundingClientRect();
			return (
				rowRect.top >= parRect.top &&
				rowRect.left >= parRect.left &&
				rowRect.bottom <= parRect.bottom &&
				rowRect.right <= parRect.right
			);
		}

		/**
		 * Calculates what table rows are visible to the user
		 * @param container table wrapper
		 * @returns Indexes of table rows visible to the user
		 */
		const determineVisibleTableRows = (container: Element) => {
			const rowElement = container.querySelectorAll('.p-selectable-row');
			const visibleRows: number[] = [];
			let i: number, j: number, currentRow: Element;

			for (i = 0, j = rowElement.length; i < j; i++) {
				currentRow = rowElement[i];

				if (isTableRowInViewport(container, currentRow)) {
					visibleRows.push(i);
				}
			}
			return visibleRows;
		}

		/**
		 * Scroll to table cell
		 * @param nextCell
		 * @param isPrevElement is next cell a previous element?
		 */
		const scrollToTableCell = (nextCell: UiTableCell, isPrevElement: boolean) => {
			// scroll to nextCellElement position when next row not completely visible
			const container = document.querySelector('.p-datatable-wrapper');
			const visibleRows: number[] = determineVisibleTableRows(container!);
			visibleRows.shift();
			if (!visibleRows.find((row: number) => row === (nextCell.pos -1))) {
				const nextCellElement = nextCell.element.$el;
				if (nextCellElement && container) {
					// check if nextCell on row previous or next element, when not then scrollIntoView
					if (!visibleRows.find((row: number) => row -1 === nextCell.pos -1 || row +1 === nextCell.pos -1)) {
						nextCellElement.scrollIntoView(false);
					} else {
						container.scrollTo({
							top: isPrevElement ? (container.scrollTop - nextCellElement.scrollHeight - 6) : (container.scrollTop + nextCellElement.scrollHeight + 6),
							left: 0,
							behavior: 'smooth',
						});
					}
				}
			}
		}

		/**
		 * Navigate in table cells
		 * @param event
		 * @param rowData
		 * @param column
		 * @param cellData
		 */
		const navigateCells = (event: KeyboardEvent, rowData: UiTableRow, column: DocumentTableColumn, cellData: DocumentTableCell) => {
			let nextCell: UiTableCell | undefined;
			const target = event.target as HTMLElement;
			const parent = target.parentElement;

			if(cellData.state === "FORCEAPPROVAL") {
				cellData.state  = "OK";
				emit("onChange", table);
			}

			if (event.key === 'Enter') {
				if (target.tagName === 'INPUT' && parent?.tagName === 'SPAN' && parent?.getAttribute('aria-expanded') === 'true') {
					// Autocomplete offen -> Navigation im Autocomplete hat Vorrang
					return;
				}
				nextCell = findCell((event.shiftKey) ? rowData.pos - 1 : rowData.pos + 1);

				if (!nextCell) {
					emit("focusLastHeadField");
				} else {
					scrollToTableCell(nextCell, event.shiftKey);
				}
			} else if (event.key === 'Tab') {
				const index = tableCells.value.findIndex(f => f.name === column.name && f.pos === rowData.pos);

				if (event.shiftKey) {
					if (index > 0) {
						nextCell = tableCells.value[index - 1];
					}
				} else {
					if (index < tableCells.value.length - 1) {
						nextCell = tableCells.value[index + 1];
					}
				}

				if (!nextCell) {
					event.preventDefault();
					emit("focusLastHeadField");
				} else {
					scrollToTableCell(nextCell, event.shiftKey);
				}
			} else if (event.key === 'ArrowDown') {
				if (target.tagName === 'INPUT' && parent?.tagName === 'SPAN' && parent?.getAttribute('aria-expanded') === 'true') {
					// Autocomplete offen -> Navigation im Autocomplete hat Vorrang
					return;
				}
				nextCell = findCell(rowData.pos + 1, column);
			} else if (event.key === 'ArrowUp') {
				if (target.tagName === 'INPUT' && parent?.tagName === 'SPAN' && parent?.getAttribute('aria-expanded') === 'true') {
					// Autocomplete offen -> Navigation im Autocomplete hat Vorrang
					return;
				}
				nextCell = findCell(rowData.pos - 1, column);
				if (nextCell) {
					scrollToTableCell(nextCell, true);
				}
			} else if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
				const input = target as HTMLInputElement;
				const len = input.value ? input.value.length : 0;

				if (input.selectionStart !== input.selectionEnd) {
					return;
				}

				if (event.key === 'ArrowLeft' && input.selectionStart === 0 && input.selectionEnd === 0) {
					const index = tableCells.value.findIndex(f => f.name === column.name && f.pos === rowData.pos);
					if (index > 0) {
						nextCell = tableCells.value[index - 1];
						if (nextCell) {
							scrollToTableCell(nextCell, true);
						}
					}
				} else if (event.key === 'ArrowRight' && input.selectionStart === len && input.selectionEnd === len) {
					const index = tableCells.value.findIndex(f => f.name === column.name && f.pos === rowData.pos);
					if (index < tableCells.value.length - 1) {
						nextCell = tableCells.value[index + 1];
						if (nextCell) {
							scrollToTableCell(nextCell, false);
						}
					}
				}
			} else {
				return;
			}

			if (nextCell) {
				event.preventDefault();
				activateCell(nextCell);
			}
		}

		/**
		 * Triggered when field changed
		 * @param event
		 */
		const onFieldChange = (event: FocusEvent) => {
			// In AutoComplete box if entry in popup list is clicked
			// first the input's blur event is triggered.
			// But in this case no further action (Validation) should be taken
			// but only after real blur (leave) of input.
			if (event && event.target) {
				const node = event.target as Node;
				if (node.parentElement && node.parentElement.getAttribute('aria-expanded') === 'true') {
					return;
				}
			}

			emit("onChange", table);
		}

		/**
		 * Set cell reference
		 * @param element
		 * @param rowData
		 * @param column
		 */
		const setCellReference = async (element: Element, rowData: UiTableRow, column: DocumentTableColumn) => {
			if (column.readonly === true || column.hidden === true) {
				return
			}

			const cell = rowData.cells[column.name!] as DocumentTableCellWithIndex;
			tableCells.value[cell.cellIndex] = {
				pos: rowData.pos,
				name: column.name || '',
				element: element,
				column: column,
			};
		}

		/**
		 * Event that is triggered on click on autocomplete-fields
		 * @param {KeyboardEvent} event
		 * @param {UiTableRow} rowData
		 * @param {DocumentTableColumn} column
		 */
		const onClickAutocomplete = (event: KeyboardEvent, rowData: UiTableRow, column: DocumentTableColumn) => {
			// Trigger onDropDownClick-Event, so the alternatives are shown when there are alternatives. Otherwise do nothing
			if (column.lookup?.active === true && column.lookup.minInputLength === 0) {
				const cell = findCell(rowData.pos, column);

				// Open Dropdown if there is one
				if (cell && cell.element && cell.element.onDropdownClick) {
					cell.element.onDropdownClick(event, column);
				}
			}
		}

		/**
		 * Triggered when a Dropdown is clicked. Currently, this triggered by clicking the Autocomplete-Field.
		 * @param event
		 * @param field
		 */
		const onClickDropdown = (event: AutoCompleteOnCompleteEvent, field: DocumentTableColumnWithLookupFilter) => {
			event.query = "";
		}

		/**
		 * Triggered when the rows are reordered
		 * @param event
		 */
		const onRowReorder = (event: any) => {
			if (!event.value) {
				return
			}

			table.rows = event.value.map((row: UiTableRow) => {
				return {
					value: row.model.value,
					cells: row.model.cells,
				}
			});

			emit("onChangeTable", table);
		}

		/**
		 * Execute the custom action in table
		 * @param {string} actionId
		 */
		const executeCustomTableAction = (actionId: string) => {
			emit("executeCustomTableAction", actionId);
		}

		/**
		 * Triggered on keydown
		 * @param {KeyboardEvent} event
		 * @param {UiTableRow} rowData
		 * @param {DocumentTableColumn} column
		 */
		const onKeydown = (event: KeyboardEvent, rowData: UiTableRow, column: DocumentTableColumn) => {
			// if key ctrl and arrow down pressed, then open the autocomplete popup
			if (event.code === 'ArrowDown' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
				onClickAutocomplete(event, rowData, column);
			}
		}

		onMounted(() => {
			Object.assign(table, props.currentTable);
			getAllTableColumnWidths();
			reloadData();
			emit("allTableRefCells", tableCells.value);
		})

		/**
		 * Triggered, when the row is selected
		 * @param event
		 */
		const onRowSelect = (event: { originalEvent: Event; data: UiTableRow; index: number; type: string }) => {
			if (event.type === "checkbox") {
				if (table && table.rows) {
					table.rows[(event.data.pos -1)].selected = true;
					emit("onChangeTable", table, true);
				}
			}
		}

		/**
		 * Triggered, when the row is unselected
		 * @param event
		 */
		const onRowUnselect = (event: { originalEvent: Event; data: UiTableRow; index: number; type: string }) => {
			if (event.type === "checkbox") {
				if (table && table.rows) {
					table.rows[(event.data.pos -1)].selected = false;
					emit("onChangeTable", table, true);
				}
			}
		}

		/** Triggered, when all rows are selected */
		const onRowSelectAll = () => {
			if (table && table.rows) {
				table.rows.forEach(row => {
					row.selected = true;
				})
				emit("onChangeTable", table, true);
			}
		}

		/** Triggered, when all rows are unselected */
		const onRowUnselectAll = () => {
			if (table && table.rows) {
				table.rows.forEach(row => {
					row.selected = false;
				})
				emit("onChangeTable", table, true);
			}
		}

		watch(() => props.resetSelectedRows, () => {
			if (props.resetSelectedRows) {
				// reset all selected rows
				selectedRows.value = [];
			}
		})

		/**
		 * Check if columns have changed (the properties hidden, mandatory, readonly, forceValidation)
		 * If true, reload the columns
		 * @param newTable
		 * @param oldTable
		 */
		const checkColumnChanges = (newTable: DocumentTable, oldTable: DocumentTable) => {
			let columnsAreChanged: boolean = false;
			if (newTable.columns && oldTable.columns) {
				newTable.columns.forEach((column) => {
					// set cell state to empty, when the column is force approval
					// This is necessary to show the style on the cell
					if (column.forceValidation !== oldTable.columns!.find(col => col.name === column.name)!.forceValidation) {
						table.rows?.find(row => {
							row.cells?.find(cell => {
								if (cell.columnName === column.name) {
									cell.state = "";
								}
							})
						});
						columnsAreChanged = true;
						emit("onChange", table);
					}

					if (column.hidden !== oldTable.columns!.find(col => col.name === column.name)!.hidden
						|| column.mandatory !== oldTable.columns!.find(col => col.name === column.name)!.mandatory
						|| column.readonly !== oldTable.columns!.find(col => col.name === column.name)!.readonly)
					{
						columnsAreChanged = true;
						return;
					}
				})
			}
			return columnsAreChanged;
		}

		watch(() => props.currentTable, (newTable: DocumentTable, oldTable: DocumentTable) => {
			Object.assign(table, props.currentTable);

			// Check if columns have changed
			const columnsAreChanged = checkColumnChanges(newTable, oldTable);

			// Only if the table has changed, the columns should be reloaded on change of the table.
			// Otherwise only reload the data
			if (newTable.name === oldTable.name && !columnsAreChanged) {
				reloadData(false);
			}
			else {
				reloadData(true);
			}
		})

		/**
		 * Triggers the check of values on autocomplete-fields. If the value is invalid, the value will be cleared.
		 * This function is needed for values that are inserted to fields via Squeeze-Viewer
		 * @param event
		 * @param column
		 * @param cell
		 * @param fieldValue
		 * @param tableIndex
		 */
		const checkAutocompleteValues = async (event: AutoCompleteOnCompleteEvent, column: DocumentTableColumn, cell: any, fieldValue: any = '', tableIndex: number) => {
			if (!fieldValue) {
				return;
			}

			const autocompleteRow = editingRows.value.find(row => row.pos === tableIndex);
			if (autocompleteRow) {
				await searchAutocomplete(event, column, autocompleteRow);

				if (filteredValues.value[column.name as any]) {
					if(!filteredValues.value[column.name as any].find((filteredValue: any) => filteredValue.value === fieldValue)) {
						cell.value = "";
						if (autocompleteRow.cells[column.name as any]) {
							autocompleteRow.cells[column.name as any].value = "";
						}
					} else {
						cell.value = fieldValue;
						// Set field value again, because otherwise the value might be empty
						autocompleteRow.cells[column.name as any].value = fieldValue;
					}
				}
			}
		}

		return {
			t,
			toast,
			store,
			loading,
			table,
			rows,
			columns,
			editingRows,
			filteredValues,
			defaultValues,
			tableCells,
			nextTableCell,
			noFocusColumns,
			showButton,
			selectedRows,
			allTableColumnWidths,
			allVisibleColumns,
			columnResize,
			reloadData,
			onItemSelect,
			searchAutocomplete,
			createNewRow,
			copyRow,
			resetColumnWidths,
			scrollToTableBottom,
			createEmptyRowModelObject,
			deleteSelectedRows,
			deleteRow,
			createDefaultValues,
			onFocusField,
			findCell,
			navigateCells,
			activateCell,
			scrollToTableCell,
			isTableRowInViewport,
			determineVisibleTableRows,
			onFieldChange,
			setCellReference,
			onClickAutocomplete,
			onClickDropdown,
			onRowReorder,
			executeCustomTableAction,
			onKeydown,
			onRowSelect,
			onRowUnselect,
			onRowSelectAll,
			onRowUnselectAll,
			checkAutocompleteValues,
		}
	},
});
